import React from "react"
import {
  Container,
  Heading,
  Box,
  Text,
  UnorderedList,
  ListItem,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Image,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Grid,
} from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom"

import AmcfDiagram from "~/assets/images/amcf-diagram.png"

const Home = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Container maxW="container.md" pt={8} pb={8}>
      <Heading as="h1">Welcome to the AMCF Demo</Heading>
      <Box mt={8}>
        <Text mb={2}>
          This is a short demonstration to give you a taste of what the Asset Management Competency Framework survey and reporting tool will look like, and how you will interact
          with it as a user.
        </Text>
        <Text mb={2}>When the tool is launched in early 2022 it will cover the full spectrum of competencies from the framework.</Text>
        <Heading as="h2" fontSize="2xl" color="brand.orange.500" mb={4} mt={4}>
          How to use the tool
        </Heading>
        <UnorderedList>
          <ListItem mb={4}>
            The intention of the survey module is to sequentially walk the user through the competency areas and sub-areas, and ask them to rate their competency on a 0-5 scale
            against each of the questions.
          </ListItem>
          <ListItem mb={4}>
            Once the individual user has completed filling out the survey, the system will provide a report that summarises their results against the 8 competency areas in the
            AMCF.
          </ListItem>
          <ListItem mb={4}>As part of the phase 1 rollout, the system will be configured to report by typical asset management role.</ListItem>
          <ListItem mb={4}>
            These reports will give the user information on the spread and distribution of their peers, without revealing any individual’s specific information.
          </ListItem>
        </UnorderedList>
        <Flex alignItems="center" justifyContent="space-between" mt={8}>
          <Button as={RouterLink} to="/survey" size="lg">
            Start the Survey
          </Button>
          <Button variant="link" size="lg" onClick={onOpen}>
            About the AMCF
          </Button>
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>What is the Asset Management Competency Framework?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={2}>
              REG recognises that individually and collectively as a sector we must ensure we have the right teams of appropriately skilled and experienced people to plan for and
              deliver great service to our customers.
            </Text>
            <Text mb={2}>
              The{" "}
              <Link href="https://www.nzta.govt.nz/roads-and-rail/road-efficiency-group/projects/asset-management-competency-framework/" target="_blank">
                Asset Management Competency Framework (AMCF)
              </Link>{" "}
              seeks to recognise the importance of our human ‘assets’ and provides a framework for identifying appropriate competencies for different aspects of asset management
              and pathways for capability enhancement. It has also been set up in an asset agnostic manner so it will be able to be used across other council asset types too.
            </Text>
            <Image src={AmcfDiagram} alt="AMCF Diagram" mb={6} mx="auto" />

            <Grid templateColumns="5fr 3fr" gap={6} mb={4}>
              <Box>
                <Text mb={2} fontWeight="bold" fontSize="xl">
                  Competency score explanation
                </Text>

                <Text mb={2}>
                  Asset management capability profiles can be built using a six-step assessment. This enables individuals and organisations to determine what level of competence is
                  required for their organisation and for individual team members. For each of the questions, individuals are asked to rate their competence on the scale below.
                  Further definition can be found in the{" "}
                  <Link href="https://www.nzta.govt.nz/assets/Road-Efficiency-Group/docs/REG-asset-management-framework.pdf" target="_blank">
                    AMCF document.
                  </Link>
                </Text>
              </Box>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Level</Th>
                    <Th>Description</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>NA</Td>
                    <Td>Not relevant to role</Td>
                  </Tr>
                  <Tr>
                    <Td>1</Td>
                    <Td>Novice</Td>
                  </Tr>
                  <Tr>
                    <Td>2</Td>
                    <Td>Beginner</Td>
                  </Tr>
                  <Tr>
                    <Td>3</Td>
                    <Td>Competent</Td>
                  </Tr>
                  <Tr>
                    <Td>4</Td>
                    <Td>Proficient</Td>
                  </Tr>
                  <Tr>
                    <Td>5</Td>
                    <Td>Expert</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  )
}

export default Home
