const colors = {
  brand: {
    orange: {
      50: "#FFEEE5",
      100: "#FFD0B8",
      200: "#FFB28A",
      300: "#FF945C",
      400: "#FF762E",
      500: "#FF5800",
      600: "#CC4600",
      700: "#993500",
      800: "#662300",
      900: "#331200",
    },
    blue: {
      50: "#E5F6FF",
      100: "#B8E5FF",
      200: "#8AD4FF",
      300: "#5CC3FF",
      400: "#2EB2FF",
      500: "#0081CC",
      600: "#005485",
      700: "#006199",
      800: "#004066",
      900: "#002033",
    },
  },
  gray: {
    50: "#f1f1f1",
    100: "#d3d8e0",
    200: "#b9bfc6",
    300: "#9fa5ac",
    400: "#858b93",
    500: "#6c727a",
    600: "#535860",
    700: "#3b3f46",
    800: "#21262d",
    900: "#020e17",
  },
  green: {
    50: "#efffe0",
    100: "#d7f9b7",
    200: "#bff38b",
    300: "#a6ee5d",
    400: "#8ee931",
    500: "#74d019",
    600: "#5aa211",
    700: "#3f7309",
    800: "#244503",
    900: "#091800",
  },
}

export default colors
