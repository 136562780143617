const styles = {
  global: {
    "html, body": {
      fontWeight: "normal",
      lineHeight: 1.5,
      color: "gray.700",
    },
    a: {
      color: "brand.blue.500",
      textDecoration: "none",
    },
    h1: {
      fontSize: "36px",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "28px",
      fontWeight: "bold",
    },
    ".description-text": {
      ul: {
        listStylePosition: "outside",
        paddingLeft: "1rem",
      },
    },
  },
}

export default styles
