const Link = {
  baseStyle: {
    textDecoration: "none",
    borderBottom: "1px solid",
    paddingBottom: "2px",
    color: "brand.secondary.400",
    _hover: {
      textDecoration: "none",
      borderBottom: "1px solid transparent",
    },
  },
}

export default Link
