const Alert = {
  parts: ["container", "alert", "icon", "title"],
  baseStyle: {
    container: {
      px: 6,
      py: 6,
      borderRadius: "md",
    },
    icon: {
      alignSelf: "flex-start",
    },
    title: {
      fontWeight: "bold",
    },
  },
}

export default Alert
