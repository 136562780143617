const Button = {
  baseStyle: {
    fontWeight: "bold",
    borderRadius: "sm",
    textDecoration: "none",
    lineHeight: 1,
  },
  variants: {
    link: {
      color: "brand.blue.600",
      textTransform: "unset",
      borderBottom: "2px solid transparent",
    },
    linkActive: {
      borderBottom: "2px solid",
      paddingLeft: "0px",
      paddingRight: "0px",
      borderRadius: "none",
      textTransform: "unset",
    },
    answer: {
      width: "44px",
      height: "44px",
      borderRadius: "22px",
      border: "5px solid",
      borderColor: "gray.100",
      fontSize: "16px",
      fontWeight: "bold",
      background: "white",
      _hover: {
        background: "brand.blue.600",
        border: "5px solid #005485",
        color: "#fff",
      },
    },
    answerSelected: {
      width: "44px",
      height: "44px",
      borderRadius: "22px",
      border: "5px solid",
      borderColor: "brand.blue.600",
      background: "brand.blue.600",
      color: "#fff",
      fontSize: "16px",
      fontWeight: "bold",
      _hover: {
        background: "brand.blue.500",
        border: "5px solid #005485",
        color: "#fff",
      },
    },
  },
  colorScheme: {
    white: {
      background: "white",
    },
  },
  sizes: {
    lg: {
      fontSize: "md",
      borderRadius: "md",
    },
    md: {
      fontSize: "md",
    },
    sm: {
      fontSize: "xs",
    },
    xs: {
      fontSize: "xs",
    },
  },
  defaultProps: {
    size: "md",
    variant: "solid",
    colorScheme: "brand.orange",
  },
}

export default Button
