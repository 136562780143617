import React, { useState, useReducer } from "react"
import {
  Container,
  Heading,
  Grid,
  Box,
  Text,
  Flex,
  Button,
  Icon,
  List,
  ListItem,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Image,
  IconButton,
} from "@chakra-ui/react"
import { FiArrowRight, FiArrowLeft, FiInfo } from "react-icons/fi"
import { useHistory } from "react-router-dom"

import SectionsProgress from "~/components/SectionsProgress"
import questionaireData from "~/services/questionaire.json"
import CompentencyLegend from "~/assets/images/compentency-legend.png"

const reducer = (state, action) => {
  switch (action.type) {
    case "updateAnswer": {
      const { currentSection, currentSubSection, questionIndex, value } = action.payload
      return {
        ...state,
        [currentSection]: {
          ...state[currentSection],
          subSections: {
            ...state[currentSection].subSections,
            [currentSubSection]: {
              ...state[currentSection].subSections[currentSubSection],
              questions: state[currentSection].subSections[currentSubSection].questions.map((x, i) => (i === questionIndex ? { ...x, answer: value } : x)),
            },
          },
        },
      }
    }
    case "calculateSubSectionScores": {
      const newState = Object.entries(state).reduce(
        (sectionAccumulator, [sectionKey, section]) => ({
          ...sectionAccumulator,
          [sectionKey]: {
            ...section,
            subSections: Object.entries(section.subSections).reduce(
              (subSectionAccumulator, [subSectionKey, subSection]) => ({
                ...subSectionAccumulator,
                [subSectionKey]: {
                  ...subSection,
                  score:
                    Math.round(
                      parseFloat(subSection.questions.map((q) => q.answer).reduce((a, b) => a + b, 0) / subSection.questions.filter((q) => q.answer > 0).length || 0) * 10
                    ) / 10,
                },
              }),
              {}
            ),
          },
        }),
        {}
      )
      return newState
    }
    // TODO: This is not accurate if there is a mix of n/a answers. Will do for the demo...
    // Need to calculate this using total questions answered
    case "calculateSectionScores": {
      const newState = Object.entries(state).reduce(
        (sectionAccumulator, [sectionKey, section]) => ({
          ...sectionAccumulator,
          [sectionKey]: {
            ...section,
            score:
              section.totalSubSections > 1
                ? parseFloat(Object.values(section.subSections).reduce((a, b) => a.score + b.score) / Object.values(section.subSections).filter((x) => x.score > 0).length)
                : Object.values(section.subSections).reduce((a) => a).score,
            subSections: Object.entries(section.subSections).reduce(
              (subSectionAccumulator, [subSectionKey, subSection]) => ({
                ...subSectionAccumulator,
                [subSectionKey]: {
                  ...subSection,
                },
              }),
              {}
            ),
          },
        }),
        {}
      )
      return newState
    }
    case "calculateSectionStatus": {
      const newState = Object.entries(state).reduce(
        (sectionAccumulator, [sectionKey, section]) => ({
          ...sectionAccumulator,
          [sectionKey]: {
            ...section,
            status: Object.values(section.subSections).reduce((acc1, x) => acc1 && x.questions.reduce((acc2, question) => acc2 && question.answer !== null, true), true)
              ? "complete"
              : "incomplete",
            subSections: Object.entries(section.subSections).reduce(
              (subSectionAccumulator, [subSectionKey, subSection]) => ({
                ...subSectionAccumulator,
                [subSectionKey]: {
                  ...subSection,
                  status: subSection.questions.reduce((acc2, question) => acc2 && question.answer !== null, true) ? "complete" : "incomplete",
                },
              }),
              {}
            ),
          },
        }),
        {}
      )
      return newState
    }
    default:
      throw new Error()
  }
}

const Questionaire = () => {
  const [state, dispatch] = useReducer(reducer, questionaireData)
  const [currentSection, setCurrentSection] = useState(1)
  const [currentSubSection, setCurrentSubSection] = useState(1)
  const totalSections = Object.keys(state).length
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const nextPage = () => {
    if (currentSubSection < state[currentSection].totalSubSections) {
      setCurrentSubSection(currentSubSection + 1)
    }
    if (currentSubSection === state[currentSection].totalSubSections && currentSection < totalSections) {
      setCurrentSubSection(1)
      setCurrentSection(currentSection + 1)
    }
  }

  const prevPage = () => {
    if (currentSubSection > 1) {
      setCurrentSubSection(currentSubSection - 1)
    }
    if (currentSubSection === 1 && currentSection > 1) {
      setCurrentSection(currentSection - 1)
      setCurrentSubSection(state[currentSection - 1].totalSubSections)
    }
  }

  const handleSelectSection = (x, y) => {
    setCurrentSection(x)
    setCurrentSubSection(y)
  }

  const updateAnswer = (questionIndex, value) => {
    dispatch({ type: "updateAnswer", payload: { currentSection, currentSubSection, questionIndex, value } })
    dispatch({ type: "calculateSubSectionScores" })
    dispatch({ type: "calculateSectionScores" })
    dispatch({ type: "calculateSectionStatus" })
  }

  const canSubmit = () =>
    Object.values(state).reduce(
      (acc, section) =>
        acc && Object.values(section.subSections).reduce((acc2, subSection) => acc2 && subSection.questions.reduce((acc3, question) => acc3 && question.answer !== null, true))
    )

  return (
    <Container maxW="container.xl" pt={8} pb={8}>
      <Heading as="h1">AMCF Survey</Heading>

      <Grid templateColumns="1fr 2fr" gap={12} mt={8}>
        <Box w="100%" position="relative">
          <SectionsProgress sections={state} currentSection={currentSection} currentSubSection={currentSubSection} onSelectSection={(x, y) => handleSelectSection(x, y)} />
        </Box>
        <Box w="100%">
          <Box
            mb={6}
            p={6}
            borderRadius="md"
            backgroundColor="brand.orange.50"
            className="description-text"
            dangerouslySetInnerHTML={{ __html: state[currentSection].description }}
          />
          <Heading as="h2" fontWeight="bold" fontSize={24} lineHeight="1.2" mb={4}>
            {currentSection}.{currentSubSection} {state[currentSection].subSections[currentSubSection].name}
          </Heading>
          <Flex alignItems="center" mb={4}>
            <Text fontSize="xs">Please rate your level of competence for each statement </Text>
            <IconButton aria-label="More Info" variant="ghost" colorScheme="brand.orange" size="xs" icon={<FiInfo size="20px" />} onClick={onOpen} ml={1} />
          </Flex>
          <List>
            {state[currentSection].subSections[currentSubSection].questions.map((q, i) => {
              const questionKey = `question${i}`
              return (
                <ListItem mb={12}>
                  <Text fontSize="16px" key={questionKey} fontWeight="bold">
                    {currentSection}.{currentSubSection}.{i + 1} {q.question}
                  </Text>

                  <Flex justifyContent="space-between" mt={4} mb={4} position="relative">
                    <Box position="absolute" h="4px" w="99%" backgroundColor="gray.100" top="50%" transform="translateY(-50%)" />
                    <Tooltip label="Not relevant to role" aria-label="Not relevant to role">
                      <Button type="button" fontSize="12px" variant={q.answer === 0 ? "answerSelected" : "answer"} onClick={() => updateAnswer(i, 0)}>
                        N/A
                      </Button>
                    </Tooltip>
                    <Tooltip label="Novice" aria-label="Novice">
                      <Button type="button" variant={q.answer === 1 ? "answerSelected" : "answer"} onClick={() => updateAnswer(i, 1)}>
                        1
                      </Button>
                    </Tooltip>
                    <Tooltip label="Beginner" aria-label="Beginner">
                      <Button type="button" variant={q.answer === 2 ? "answerSelected" : "answer"} onClick={() => updateAnswer(i, 2)}>
                        2
                      </Button>
                    </Tooltip>
                    <Tooltip label="Competent" aria-label="Competent">
                      <Button type="button" variant={q.answer === 3 ? "answerSelected" : "answer"} onClick={() => updateAnswer(i, 3)}>
                        3
                      </Button>
                    </Tooltip>
                    <Tooltip label="Proficient" aria-label="Proficient">
                      <Button type="button" variant={q.answer === 4 ? "answerSelected" : "answer"} onClick={() => updateAnswer(i, 4)}>
                        4
                      </Button>
                    </Tooltip>
                    <Tooltip label="Expert" aria-label="Expert">
                      <Button type="button" variant={q.answer === 5 ? "answerSelected" : "answer"} onClick={() => updateAnswer(i, 5)}>
                        5
                      </Button>
                    </Tooltip>
                  </Flex>
                </ListItem>
              )
            })}
          </List>
          <Box>
            <Text fontSize="24px" textAlign="right">
              Average Score: {state[currentSection].subSections[currentSubSection].score || "0.0"}
            </Text>
          </Box>
          <Flex justifyContent="space-between" mt={8} pt={6} pb={6} width="100%" borderTop="1px solid" borderBottom="1px solid" borderColor="gray.100">
            <Button type="button" variant="link" onClick={prevPage} isDisabled={currentSection === 1 && currentSubSection === 1} title="Previous">
              <Icon as={FiArrowLeft} mr={2} />
              Previous Section
            </Button>
            <Button
              type="button"
              variant="link"
              onClick={nextPage}
              isDisabled={currentSection === totalSections && currentSubSection === state[currentSection].totalSubSections}
              title="Next"
            >
              Next Section <Icon as={FiArrowRight} ml={2} />
            </Button>
          </Flex>
          <Flex justifyContent="flex-end" mt={8} width="100%">
            <Button onClick={() => history.push("/report")} to="/report" size="lg" isDisabled={!canSubmit()}>
              Submit
            </Button>
          </Flex>
        </Box>
      </Grid>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Six Level Assessment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={CompentencyLegend} alt="Legend" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  )
}

export default Questionaire
