import { extendTheme } from "@chakra-ui/react"
import Alert from "./components/alert"
import Link from "./components/link"
import Button from "./components/button"
import Heading from "./components/heading"
import Text from "./components/text"
import colors from "./foundations/colors"
import container from "./foundations/sizes"
import typography from "./foundations/typography"
import styles from "./foundations/styles"

export default extendTheme({
  styles,
  colors,
  sizes: { container },
  ...typography,
  components: { Alert, Button, Heading, Text, Link },
})
