import React from "react"
import { ChakraProvider } from "@chakra-ui/react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import theme from "~/assets/theme"
import AppHeader from "~/components/shared/AppHeader"
import Home from "~/views/Home"
import Questionaire from "~/views/Questionaire"
import Report from "~/views/Report"

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <AppHeader />
        <Switch>
          <Route path="/survey">
            <Questionaire />
          </Route>
          <Route path="/report">
            <Report />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </ChakraProvider>
  )
}

export default App
