/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { Image, Box, Flex, Link } from "@chakra-ui/react"
import { NavLink as RouterLink } from "react-router-dom"

import Logo from "~/assets/images/reg-logo.svg"
import CornerFold from "~/assets/images/fold.svg"

const AppHeader = () => (
  <Box as="header" w="100%" backgroundColor="brand.orange.500" position="relative">
    <Flex wrap="wrap" align="center" justify="space-between" p={3}>
      <Link as={RouterLink} to="/" border="none!important" p={0}>
        <Image src={Logo} height="60px" alt="Reg Logo" />
      </Link>
    </Flex>
    <Image src={CornerFold} alt="Fold" position="absolute" bottom="0" right="0" />
  </Box>
)

export default AppHeader
