import React from "react"
import PropTypes from "prop-types"
import { Box, Flex, Text, Icon } from "@chakra-ui/react"
import { FiCheck, FiChevronRight } from "react-icons/fi"

const SectionsProgress = ({ sections, currentSection, currentSubSection, onSelectSection }) => {
  const renderSectionStatusColor = (sectionKey) => {
    if (currentSection.toString() === sectionKey) {
      return "brand.orange.400"
    }
    if (sections[sectionKey].status === "complete") {
      return "green.600"
    }
    return "gray.400"
  }

  const renderSubSectionStatusColor = (sectionKey, subSectionKey) => {
    if (currentSection.toString() === sectionKey && currentSubSection.toString() === subSectionKey) {
      return "brand.orange.400"
    }
    if (sections[sectionKey].subSections[subSectionKey].status === "complete") {
      return "green.600"
    }
    return "gray.400"
  }

  const handleSelectSection = (sectionKey, subSectionKey) => {
    onSelectSection(parseInt(sectionKey, 10), parseInt(subSectionKey, 10))
  }

  return (
    <Box position="sticky" top={4}>
      {Object.entries(sections).map(([sectionKey, section]) => (
        <Box key={`section-${sectionKey}`}>
          <Flex
            w="100%"
            alignItems="center"
            position="relative"
            zIndex="2"
            mt={parseInt(sectionKey, 10) !== 1 ? 4 : ""}
            mb={4}
            onClick={() => handleSelectSection(sectionKey, 1)}
            cursor="pointer"
          >
            <Box w="60px" h="60px" display="flex" justifyContent="center" alignItems="center" borderRadius="full" backgroundColor={renderSectionStatusColor(sectionKey)} mr="16px">
              <Text fontSize={32} color="white">
                {section.status === "complete" && currentSection !== parseInt(sectionKey, 10) ? <Icon as={FiCheck} /> : sectionKey}
              </Text>
            </Box>
            <Box flex="1">
              <Text fontSize={20} fontWeight="bold" lineHeight="1.2" color={renderSectionStatusColor(sectionKey)}>
                {section.name}
              </Text>
              {section.status === "complete" && <Text fontSize={12}>Average Score: {section.score}</Text>}
            </Box>
          </Flex>
          {currentSection === parseInt(sectionKey, 10) &&
            Object.entries(section.subSections).map(([subSectionKey, subSection]) => (
              <Flex
                w="100%"
                alignItems="center"
                mt={4}
                mb={4}
                position="relative"
                zIndex="2"
                key={`subSection-${sectionKey}_${subSectionKey}`}
                onClick={() => handleSelectSection(sectionKey, subSectionKey)}
                cursor="pointer"
              >
                <Box
                  w="20px"
                  h="20px"
                  ml="20px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="full"
                  backgroundColor={renderSubSectionStatusColor(sectionKey, subSectionKey)}
                  color="white"
                >
                  {subSection.status === "complete" && currentSubSection !== parseInt(subSectionKey, 10) ? <Icon as={FiCheck} boxSize="14px" /> : ""}
                  {currentSubSection === parseInt(subSectionKey, 10) ? <Icon as={FiChevronRight} boxSize="14px" /> : ""}
                </Box>
                <Box flex="1">
                  <Text fontSize={14} ml="36px" color={renderSubSectionStatusColor(sectionKey, subSectionKey)}>
                    {subSection.name}
                  </Text>
                </Box>
              </Flex>
            ))}
        </Box>
      ))}
      <Box w="4px" h="100%" backgroundColor="gray.100" position="absolute" left="28px" top="0" zIndex="1" />
    </Box>
  )
}

SectionsProgress.propTypes = {
  sections: PropTypes.object.isRequired,
  currentSection: PropTypes.number.isRequired,
  currentSubSection: PropTypes.number.isRequired,
  onSelectSection: PropTypes.func.isRequired,
}

export default SectionsProgress
