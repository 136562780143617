const typography = {
  fonts: {
    heading: "Arial, sans-serif",
    body: "Arial, sans-serif",
  },
  fontWeights: {
    light: 400,
    normal: 400,
    medium: 400,
    semibold: 700,
    bold: 700,
  },
}

export default typography
