import React, { useRef, useEffect } from "react"
import {
  Container,
  Heading,
  Grid,
  Box,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
  Button,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  useDisclosure,
  Link,
  Icon,
} from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom"
import { FiAlertCircle } from "react-icons/fi"

import CSGraph from "~/assets/images/compentency-summary-graph.png"
import PGGraph from "~/assets/images/peer-group-graph.png"
import ARGraph from "~/assets/images/am-role-graph.png"
import AGraph from "~/assets/images/age-graph.png"

const ExampleBanner = () => (
  <Text
    transform="rotate(-31deg) translate(-50%, -50%)"
    position="absolute"
    left="46%"
    top="12%"
    fontSize="4xl"
    fontWeight="bold"
    background="whiteAlpha.700"
    p={4}
    w="100%"
    color="gray.600"
    textAlign="center"
  >
    EXAMPLE ONLY
  </Text>
)

const Report = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()

  useEffect(() => {
    onOpen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box background="#f9f9f9">
      <Container maxW="container.xl" pt={8} pb={8}>
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef} size="xl">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Demo Complete</DrawerHeader>
            <DrawerBody>
              <Alert status="success">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle mb={1} fontSize="lg">
                    Congratulations on completing this demonstration!
                  </AlertTitle>
                  <AlertDescription maxWidth="sm">
                    We hope this this has helped you get enthusiastic about your own career development, and we look forward to working with you next year.
                  </AlertDescription>
                </Box>
              </Alert>
              <Box p={6} mt={4} mb={8} border="2px solid" borderColor="gray.100" borderRadius="md">
                <Heading as="h2" fontSize="2xl" color="brand.orange.500" mb={4}>
                  When will I be able to access the platform?
                </Heading>
                <Text mb={2}>The platform will be available from late March 2022 onwards.</Text>
                <Text mb={2}>
                  A pilot programme will be run from mid-January to mid-March 2022, to ensure that the platform is appropriately configured for a range of users. If you or your
                  organisation are interesting in getting involved in this phase, please make contact via <Link href="mailto:reg@nzta.govt.nz">reg@nzta.govt.nz</Link>.
                </Text>

                <Heading as="h2" fontSize="2xl" color="brand.orange.500" mb={4} mt={4}>
                  How does this contribute to my career?
                </Heading>
                <Text mb={2}>This will be a tool that you can use to help track your progress, and create a road map for development.</Text>
                <Text>
                  Once the survey is completed users will be able to chart the results and show capability. Individual results will be aggregated to create average results by peer
                  group. These two views will highlight where you need to focus. You can use this information to assist with your annual career discussions with your employer and
                  target your training efforts.
                </Text>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <Flex alignItems="center" justifyContent="space-between" mb={8}>
          <Heading as="h1" margin="none">
            AMCF User Report
          </Heading>
          <Button as={RouterLink} to="/" size="lg">
            Start Again?
          </Button>
        </Flex>
        <Text display="flex" alignItems="center">
          <Icon as={FiAlertCircle} boxSize="24px" mr={2} /> The graphs below are examples only and do not contain real data
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" gap={8} mt={8}>
          <Box p={4} boxShadow="sm" borderRadius="sm" backgroundColor="white" position="relative" overflow="hidden">
            <Heading fontSize="xl" textAlign="center" mb={8}>
              Competency Summary
            </Heading>
            <Image src={CSGraph} mx="auto" />
            <ExampleBanner />
          </Box>
          <Box p={4} boxShadow="sm" borderRadius="sm" backgroundColor="white" position="relative" overflow="hidden">
            <Heading fontSize="xl" textAlign="center" mb={8}>
              Peer group competency results - Asset Manager
            </Heading>
            <Image src={PGGraph} mx="auto" />
            <ExampleBanner />
          </Box>
          <Box p={4} boxShadow="sm" borderRadius="sm" backgroundColor="white" position="relative" overflow="hidden">
            <Heading fontSize="xl" textAlign="center" mb={8}>
              Typical Asset Management Role
            </Heading>
            <Image src={ARGraph} mx="auto" />
            <ExampleBanner />
          </Box>
          <Box p={4} boxShadow="sm" borderRadius="sm" backgroundColor="white" position="relative" overflow="hidden">
            <Heading fontSize="xl" textAlign="center" mb={8}>
              Age (years)
            </Heading>
            <Image src={AGraph} mx="auto" />
            <ExampleBanner />
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default Report
